<template>
  <b-modal
    id="basket_modal"
    centered
    size="sm"
    hide-header
    hide-footer
    @close="handleClose"
    @hide="handleClose"
  >
    <div class="text-center mb-3">
      <span class="d-inline-block text-dark h5 shadow-sm p-2 font-weight-bolder rounded border">
        Səbətə Əlavə Et
      </span>
    </div>

    <div class="basket-options">
      <div v-for="(basket, index) in baskets" :key="index" class="basket-row mb-2">
        <div class="d-flex align-items-center justify-content-between p-2 border rounded">
          <div class="d-flex align-items-center">
            <b-icon-cart4 :variant="basket.color" class="mr-2" />
            <span class="font-weight-bold">{{ basket.name }}</span>
          </div>
          <div class="quantity-controls">
            <b-button
              variant="light"
              size="sm"
              class="border"
              @click="decrementQuantity(basket)"
              :disabled="basket.quantity <= 0"
            >-</b-button>
            <span class="mx-2 font-weight-bold">{{ basket.quantity }}</span>
            <b-button
              variant="light"
              size="sm"
              class="border"
              @click="incrementQuantity(basket)"
            >+</b-button>
          </div>
        </div>
      </div>
    </div>

    <div class="text-center mt-3">
      <b-button
        variant="light"
        @click="handleClose"
        class="mr-2 font-weight-bold border shadow-sm text-danger"
      >
        Ləğv et
      </b-button>
      <b-button
        variant="success"
        class="font-weight-bold"
        @click="handleSubmit"
        :disabled="isSubmitting"
      >
        {{ isSubmitting ? 'Əlavə edilir...' : 'Təsdiq Et' }}
      </b-button>
    </div>
  </b-modal>
</template>

<script>
import axios from "axios";

export default {
  name: "BasketModal",
  data() {
    return {
      baskets: [
        { id: 1, name: 'Elit', quantity: 0, color: 'primary' },
        { id: 2, name: 'Çobanzadə', quantity: 0, color: 'success' }
      ],
      product_id: null,
      initial_warehouse: null,
      initial_quantity: 0,
      isSubmitting: false
    };
  },
  mounted() {
    this.$root.$on('bv::show::modal', (modalId, data) => {
      this.handleModalShow(modalId, data);
    });
  },
  methods: {
    handleModalShow(modalId, data) {
      if (modalId === 'basket_modal' && data) {
        console.log('Modal show data:', data);
        this.resetModal();
        console.log("handleModalShow data:", data)
        this.product_id = parseInt(data.product_id);
        this.initial_warehouse = parseInt(data.warehouse_id);
        this.initial_quantity = parseInt(data.quantity) || 0;
        
        // Set quantity only for the selected warehouse
        if (this.initial_warehouse) {
          console.log('Setting initial quantity for warehouse:', this.initial_warehouse);
          const basket = this.baskets.find(b => b.id === this.initial_warehouse);
          if (basket) {
            basket.quantity = this.initial_quantity;
            console.log('Updated basket state:', basket);
          }
        }
        console.log('After modal setup - product_id:', this.product_id);
        console.log('Current baskets state:', this.baskets);

        // Show the modal
        this.$bvModal.show(modalId);
      }
    },
    resetModal() {
      this.baskets.forEach(basket => {
        basket.quantity = 0;
      });
      this.initial_warehouse = null;
      this.initial_quantity = 0;
    },
    incrementQuantity(basket) {
      basket.quantity = (parseInt(basket.quantity) || 0) + 1;
    },
    decrementQuantity(basket) {
      if (basket.quantity > 0) {
        basket.quantity = parseInt(basket.quantity) - 1;
      }
    },
    handleClose() {
      this.resetModal();
      this.$bvModal.hide('basket_modal');
    },
    async handleSubmit() {
      if (this.isSubmitting) return;
      
      console.log('Submitting with product_id:', this.product_id);
      console.log('Current basket states:', this.baskets);
      const basketsToUpdate = this.baskets.filter(basket => basket.quantity > 0);
      console.log('Baskets to update:', basketsToUpdate);
      
      if (basketsToUpdate.length === 0) {
        this.$store.commit('SET_GLOBAL_TOAST', {
          message: 'Ən azı bir səbətə məhsul əlavə edin',
          variant: 'warning'
        });
        return;
      }

      if (!this.product_id) {
        console.error('No product_id set');
        this.$store.commit('SET_GLOBAL_TOAST', {
          message: 'Xəta baş verdi: Məhsul tapılmadı',
          variant: 'danger'
        });
        return;
      }

      this.isSubmitting = true;
      try {
        let successCount = 0;
        for (const basket of basketsToUpdate) {
          if (basket.quantity <= 0) continue;
          
          const data = {
            product_id: this.product_id,
            quantity: parseInt(basket.quantity),
            warehouse_id: basket.id
          };
          
          console.log('Sending basket data:', data);
          const response = await axios.post('/basket/add', data);
          console.log('Response from server:', response.data);
          
          if (response.data.success) {
            successCount++;
          }
        }
        
        if (successCount > 0) { 
          this.$store.commit('SET_GLOBAL_TOAST', {
            message: 'Məhsul səbətə əlavə edildi',
            success: 'success'
          });
          this.$emit('basket-updated');
          this.handleClose();
        }
      } catch (error) {
        console.error('Error adding to basket:', error);
        this.$store.commit('SET_GLOBAL_TOAST', {
          message: 'Xəta baş verdi',
          variant: 'danger'
        });
      } finally {
        this.isSubmitting = false;
      }
    }
  },
  beforeDestroy() {
    this.$root.$off('bv::show::modal', this.handleModalShow);
  }
};
</script>

<style scoped>
.basket-options {
  max-width: 300px;
  margin: 0 auto;
}

.basket-row {
  transition: all 0.2s ease;
}

.basket-row:hover {
  transform: translateY(-1px);
}

.quantity-controls {
  display: flex;
  align-items: center;
}

.quantity-controls button {
  width: 30px;
  height: 30px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
