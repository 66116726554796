<template>
  <RowCol v-if="messages && messages.length > 0">
    <div
      class="d-inline-block text-dark
         shadow-sm p-3 mt-2 font-weight- rounded border"
      style="border-radius: 12px !important;"
      v-for="message in messages"
      :key="message.id"
    >
      {{ message.name }}
    </div>
  </RowCol>
</template>

<script>
import RowCol from "@/components/RowCol";

export default {
  name: "Messages",
  components: { RowCol },
  props: ["messages"],
};
</script>
