<template>
  <div class="page-wrapper">
    <Navbar />
    <div class="page-content">
      <b-container class="text-center">
        <RowCol>
          <SectionHeader name="Qaimələr" />
        </RowCol>
        <RowCol v-if="accounting_disabled">
          <SectionHeader type="text-secondary" name="Qalıq Borc Hesablanır..." />
        </RowCol>
        <RowCol v-if="!accounting_disabled">
          <SectionHeader
            type="text-danger"
            class="ml-3"
            :name="'Borc: ' + parseFloat(borc).toFixed(2)"
          />
        </RowCol>

        <RowCol>
          <b-table
            v-if="items && items.length > 0"
            class="shadow"
            bordered
            hover
            small
            :items="items"
            :fields="fields"
          >
            <template #cell(pub_date)="data">
              {{ data.item.pub_date | format_date }}
            </template>
            <template #cell(warehouse_id)="data">
              <span v-if="data.item.warehouse_id == 1 || data.item.warehouse_id == null">Elit</span>
              <span v-else-if="data.item.warehouse_id == 2">Çobanzadə</span>
            </template>

            <template #cell(-)="data">
              <b-button
                variant="light"
                class="border shadow-sm font-weight-bold text-primary"
                v-b-modal.details_modal
                @click="showInvoiceDetails(data.item.id)"
              >
                Göstər
              </b-button>
            </template>
          </b-table>
        </RowCol>
        <b-modal id="details_modal" hide-header centered size="xl" hide-footer>
          <div v-if="disabled" class="d-flex flex-column align-items-center">
            <b-spinner />
          </div>
          <b-table
            v-if="!disabled"
            bordered
            hover
            small
            :items="detail_items"
            :fields="detail_fields"
            class="text-center"
          >
            <template #cell(N)="data">
              {{ data.index + 1 }}
            </template>
            <template #cell(price)="data">
              {{ parseFloat(data.item.price).toFixed(2) }}
            </template>
            <template #cell(description)="data">
              {{
                data.item.description && data.item.description.length > 0
                  ? data.item.description.substring(0, 30)
                  : null
              }}
            </template>
            <template #cell(mebleg)="data">
              {{ parseFloat(data.item.quantity * data.item.price).toFixed(2) }}
            </template>
          </b-table>
        </b-modal>
      </b-container>
    </div>
  </div>
</template>
<script>
import Navbar from "@/components/Navbar";
import axios from "axios";
import RowCol from "@/components/RowCol";
import SectionHeader from "@/components/SectionHeader";

export default {
  name: "SalesInvoices",
  components: { SectionHeader, RowCol, Navbar },
  data() {
    return {
      currentPage: 1,
      items: [],
      detail_items: [],
      fields: [
        { key: "id", label: "Qaimə N:" },
        { key: "pub_date", label: "Tarix" },
        { key: "warehouse_id", label: "Filial" },
        {
          key: "amount",
          label: "Cəm Məbləğ (AZN)",
        },
        "-",
      ],
      detail_fields: [
        "N",
        { key: "brand", label: "Brend" },
        { key: "description", label: "Malın Adı" },
        { key: "kod", label: "Kod" },
        { key: "quantity", label: "Say" },
        { key: "price", label: "Qiymət (AZN) " },
        { key: "mebleg", label: "Məbləğ (AZN)" },
      ],
      total: null,
      disabled: false,
      accounting_disabled: false,
      borc: 0,
    };
  },
  created() {
    this.fetchResults();
    this.$store.commit("SET_SECTION", "QAİMƏLƏR");
  },
  methods: {
    fetchResults() {
      this.disabled = true;
      this.accounting_disabled = true;
      const data = { page: this.currentPage };
      axios
        .post("/sales_invoices", data)
        .then((data) => {
          this.items = data.data.results;
          this.disabled = false;
        })
        .then(() => {
          axios.post("/sales_invoices_credit_advance").then((result) => {
            this.borc = result.data.borc;
            this.accounting_disabled = false;
          });
        });
    },
    showInvoiceDetails(id) {
      const data = { invoice_id: id };
      this.disabled = true;
      axios.post("/sales_invoices/modal", data).then((result) => {
        this.disabled = false;
        this.detail_items = result.data.results;
      });
    },
  },
};
</script>

<style scoped>
.page-wrapper {
  width: 100%;
}

.page-content {
  padding: 1rem;
}

@media (max-width: 768px) {
  .page-content {
    padding-top: 60px;
    position: relative;
    z-index: 1;
  }
}
</style>
