<template>
  <nav class="modern-navbar mb-4 ">
    <!-- border radius -->
    <div class="navbar-content border border-radius ">
      <!-- Logo -->
      <div class="navbar-brand">
        <b-img
          @click="$router.push({ name: 'Stock' })"
          src="https://tnm-files.fra1.cdn.digitaloceanspaces.com/solo/common-files/logo.svg"
          height="32"
          class="navbar-logo"
        />
      </div>

      <!-- Navigation Items -->
      <div class="nav-items" :class="{ 'nav-active': isNavActive }">
        <div
          class="nav-link"
          :class="{ active: $router.currentRoute.name === 'Stock' }"
          @click="$router.push({ name: 'Stock' })"
        >
          <i class="fas fa-box"></i>
          Məhsullar
        </div>
        <div
          class="nav-link"
          :class="{ active: $router.currentRoute.name === 'BasketOrders' }"
          @click="$router.push({ name: 'BasketOrders' })"
        >
          <i class="fas fa-shopping-basket"></i>
          Sifarişlər
        </div>
        <div
          class="nav-link"
          :class="{ active: $router.currentRoute.name === 'SalesInvoices' }"
          @click="$router.push({ name: 'SalesInvoices' })"
        >
          <i class="fas fa-file-invoice"></i>
          Qaimələr
        </div>
        <div
          class="nav-link"
          :class="{ active: $router.currentRoute.name === 'DubaiOrders' }"
          @click="$router.push({ name: 'DubaiOrders' })"
        >
          <i class="fas fa-truck"></i>
          Dubay Sifarişləri
        </div>
        <div
          class="nav-link"
          :class="{ active: $router.currentRoute.name === 'CustomerPayments' }"
          @click="$router.push({ name: 'CustomerPayments' })"
        >
          <i class="fas fa-money-bill-wave"></i>
          Ödənişlər
        </div>

        <!-- Info Dropdown -->
        <div class="nav-dropdown">
          <div class="nav-link dropdown-toggle" @click="toggleDropdown('info')">
            <i class="fas fa-user-circle"></i>
          </div>
          <div class="dropdown-menu" v-show="activeDropdown === 'info'">
            <div class="dropdown-item user-item">
              <i class="fas fa-user-circle mr-2"></i>
              {{ $store.state.username }}
            </div>
            <div class="dropdown-item logout-item" @click="logout">
              <i class="fas fa-sign-out-alt mr-2"></i>
              Çıxış
            </div>
          </div>
        </div>
      </div>

      <!-- Mobile Toggle -->
      <div class="mobile-toggle" @click="toggleNav">
        <i :class="isNavActive ? 'fas fa-times' : 'fas fa-bars'"></i>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: "Nav",
  data() {
    return {
      isNavActive: false,
      activeDropdown: null,
    };
  },
  methods: {
    logout() {
      this.$store.dispatch("LOGOUT");
    },
    toggleNav() {
      this.isNavActive = !this.isNavActive;
    },
    toggleDropdown(dropdown) {
      this.activeDropdown = this.activeDropdown === dropdown ? null : dropdown;
    },
  },
};
</script>

<style scoped>
:root {
  --navbar-height-mobile: 60px;
}

.modern-navbar {
  position: sticky;
  top: 0.75rem;
  z-index: 1030;
  margin: 0 auto;
  max-width: 1200px;
  background: transparent;
}

.navbar-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.75rem;
  border: 1px solid rgba(0, 0, 0, 0.1) !important;
  border-radius: 12px !important;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.06);
  background: transparent;
}

.border-white {
  border: none !important;
}

.navbar-logo {
  height: 32px;
  cursor: pointer;
  transition: opacity 0.2s;
}

.navbar-logo:hover {
  opacity: 0.8;
}

.nav-items {
  display: flex;
  align-items: center;
  gap: 1.5rem;
}

.nav-link {
  color: #333;
  font-weight: 500;
  cursor: pointer;
  padding: 0.5rem 0;
  position: relative;
  font-size: 0.9rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.nav-icon {
  font-size: 1.1rem;
}

.item-icon {
  margin-right: 0.5rem;
  font-size: 1rem;
  color: #666;
}

.nav-link:hover {
  color: #f8c84a;
}

.nav-link:hover .nav-icon {
  color: #f8c84a;
}

.nav-link.active {
  color: #f8c84a;
}

.nav-link.active .nav-icon {
  color: #f8c84a;
}

.nav-link.active::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background: #f8c84a;
}

.nav-dropdown {
  position: relative;
}

.dropdown-menu {
  display: none;
  position: absolute;
  top: 100%;
  right: 0;
  background: white;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
  min-width: 220px;
  border: 1px solid rgba(0, 0, 0, 0.05);
  padding-top: 8px;
  margin-top: -8px;
}

.nav-dropdown:hover .dropdown-menu {
  display: block;
  animation: dropdownFade 0.2s ease;
}

@keyframes dropdownFade {
  from {
    opacity: 0;
    transform: translateY(-8px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.dropdown-item {
  padding: 0.6rem 1rem;
  color: #333;
  cursor: pointer;
  transition: all 0.2s;
  font-size: 0.85rem;
  display: flex;
  align-items: center;
}

.dropdown-item:hover {
  background: rgba(248, 200, 74, 0.1);
}

.dropdown-item:hover .item-icon {
  color: #f8c84a;
}

.dropdown-divider {
  height: 1px;
  background: rgba(0, 0, 0, 0.05);
  margin: 0.4rem 0;
}

.user-item {
  display: flex;
  align-items: center;
}

.logout-item {
  color: #dc3545;
  font-weight: 500;
}

.logout-item .item-icon {
  color: #dc3545;
}

.mobile-toggle {
  display: none;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  cursor: pointer;
  z-index: 1031;
  border-radius: 8px;
  transition: background-color 0.2s ease;
}

.mobile-toggle:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.mobile-toggle i {
  font-size: 1.5rem;
  color: #333;
}

.nav-close {
  display: none;
}

@media (max-width: 768px) {
  .modern-navbar {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    margin: 0;
    border-radius: 0;
    background: white;
    z-index: 1030;
    height: var(--navbar-height-mobile);
  }

  .navbar-content {
    height: 100%;
    padding: 0 1rem;
    border-radius: 0 !important;
    border: none !important;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    position: relative;
  }

  .mobile-toggle {
    display: flex;
    position: absolute;
    right: 1rem;
    top: 50%;
    transform: translateY(-50%);
  }

  .nav-items {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: white;
    padding: 2rem;
    transform: translateX(100%);
    transition: transform 0.3s ease;
    z-index: 1030;
  }

  .nav-items.nav-active {
    transform: translateX(0);
  }

  .nav-link {
    width: 100%;
    padding: 1rem;
    border-radius: 8px;
    font-size: 1.1rem;
    justify-content: center;
  }

  .nav-link:hover,
  .nav-link.active {
    background: rgba(248, 200, 74, 0.1);
  }

  .nav-link.active::after {
    display: none;
  }

  .nav-dropdown {
    width: 100%;
  }

  .dropdown-menu {
    position: static;
    width: 100%;
    margin-top: 0.5rem;
    box-shadow: none;
    border: 1px solid rgba(0, 0, 0, 0.1);
  }

  .dropdown-item {
    padding: 1rem;
    justify-content: center;
  }
}
</style>
