<template>
  <div class="page-wrapper">
    <Navbar />
    <div class="page-content">
      <b-container class="text-center">
        <RowCol>
          <SectionHeader name="Ödənişlər" />
        </RowCol>
        <RowCol>
          <b-table
            class="shadow"
            bordered
            hover
            small
            :items="items"
            :fields="fields"
          >
            <template #cell(pub_date)="data">
              {{ data.item.pub_date | format_date }}
            </template>
            <template #cell(amount)="data">
              {{ parseFloat(data.item.amount).toFixed(2) }}
            </template>
          </b-table>
        </RowCol>
      </b-container>
    </div>
  </div>
</template>
<script>
import Navbar from "@/components/Navbar";
import axios from "axios";
import RowCol from "@/components/RowCol";
import SectionHeader from "@/components/SectionHeader";

export default {
  name: "CustomerPayments",
  components: { SectionHeader, RowCol, Navbar },
  data() {
    return {
      currentPage: 1,
      items: [],
      fields: [
        { key: "pub_date", label: "Tarix" },
        { key: "amount", label: "Məbləğ (AZN) " },
      ],
      disabled: false,
    };
  },
  created() {
    this.fetchResults();
    this.$store.commit("SET_SECTION", "ÖDƏNİŞLƏR");
  },

  methods: {
    fetchResults() {
      this.disabled = true;
      const data = { page: this.currentPage };
      axios.post("/payments", data).then((data) => {
        this.disabled = false;
        this.items = data.data.results;
      });
    },
  },
};
</script>
<style scoped>
.page-wrapper {
  width: 100%;
}

.page-content {
  padding: 1rem;
}

@media (max-width: 768px) {
  .page-content {
    padding-top: 60px;
    position: relative;
    z-index: 1;
  }
}
</style>
