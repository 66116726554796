<template>
  <div>
    <b-toast
      toaster="b-toaster-bottom-center"
      v-model="$store.state.toast_show"
      no-close-button
      :append-toast="true"
      :class="['custom-toast', $store.state.toast_variant]">
      <div class="toast-content">
        <div class="icon-wrapper">
          <b-icon-check-circle-fill
            v-if="$store.state.toast_variant === 'success'"
            class="toast-icon success"/>
          <b-icon-x-circle-fill
            v-if="$store.state.toast_variant === 'danger'"
            class="toast-icon danger"/>
        </div>
        <span class="toast-message">
          {{ $store.state.toast_message }}
        </span>
      </div>
    </b-toast>
  </div>
</template>

<script>
export default {
  name: "GlobalToast",
};
</script>

<style scoped>
.custom-toast {
  min-width: 300px;
  padding: 1rem;
  border: none !important;
  border-radius: 12px !important;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1) !important;
  background: white !important;
}

.toast-content {
  display: flex;
  align-items: center;
  gap: 12px;
}

.icon-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
}

.toast-icon {
  font-size: 1.25rem;
}

.toast-icon.success {
  color: #28a745;
}

.toast-icon.danger {
  color: #dc3545;
}

.toast-message {
  font-size: 14px;
  font-weight: 500;
  color: #333;
}

/* Custom animations */
.custom-toast.b-toast-enter-active,
.custom-toast.b-toast-leave-active {
  transition: all 0.3s ease-out;
}

.custom-toast.b-toast-enter,
.custom-toast.b-toast-leave-to {
  opacity: 0;
  transform: translateY(20px);
}
</style>