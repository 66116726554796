<template>
  <div class="home-container">
    <LoginModal />
    <GlobalToast />

    <!-- Hero Section -->
    <div class="hero-section">
      <nav class="navbar">
        <div class="logo-container">
          <b-img
            src="https://tnm-files.fra1.cdn.digitaloceanspaces.com/solo/common-files/logo.svg"
            alt="tnm_logo"
            height="120"
            width="auto"
            class="main-logo"
          />
        </div>
      </nav>

      <div class="hero-content">
        <h1 class="hero-title">
          Avtomobil Ehtiyat Hissələrinin
          <span class="highlight">B2B Platforması</span>
        </h1>

        <p class="hero-subtitle">
          Topdan satış üçün rəqəmsal həll
        </p>

        <div class="cta-section">
          <b-button v-b-modal.login_modal class="cta-button">
            Sistemə Daxil ol
            <i class="fas fa-sign-in-alt bg-transparent"></i>
          </b-button>
        </div>
      </div>
    </div>

    <!-- Contact Section -->
    <div class="contact-section">
      <div class="contact-card">
        <h2 class="contact-title">Bizimlə Əlaqə</h2>
        <div class="contact-items">
          <div class="contact-item">
            <i class="fas fa-building mb-3"></i>
            <div class="branch-name">Elit filialı</div>
            <a
              href="https://wa.me/994706002756"
              target="_blank"
              class="phone-number"
            >
              <i class="fab fa-whatsapp"></i>
              070 600 27 56
            </a>
          </div>

          <div class="contact-divider"></div>

          <div class="contact-item">
            <i class="fas fa-building mb-3"></i>
            <div class="branch-name">Çobanzadə filialı</div>
            <a
              href="https://wa.me/994507002756"
              target="_blank"
              class="phone-number"
            >
              <i class="fab fa-whatsapp"></i>
              050 700 27 56
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LoginModal from "@/components/LoginModal";
import GlobalToast from "@/components/GlobalToast";

export default {
  name: "Home",
  components: { GlobalToast, LoginModal },
};
</script>

<style scoped>
.home-container {
  min-height: 100vh;
  background: #f8f9fa;
  position: relative;
}

.navbar {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.5rem 2rem;
  max-width: 1200px;
  margin: 0 auto;
  width: 100%;
  background: #f8f9fa;
}

.logo-container {
  text-align: center;
}

.main-logo {
  height: 120px;
  width: auto;
  max-width: 100%;
}

.hero-section {
  display: flex;
  flex-direction: column;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 2rem;
  min-height: 65vh;
  background: #f8f9fa;
}

.hero-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: -1rem;
  padding: 0 1rem;
}

.hero-title {
  font-size: 2.75rem;
  font-weight: 700;
  color: #1a1a1a;
  margin-bottom: 0.75rem;
  line-height: 1.2;
}

.highlight {
  color: #f8c84a;
  position: relative;
  display: block;
  margin-top: 0.25rem;
}

.hero-subtitle {
  font-size: 1.15rem;
  color: #666;
  margin-bottom: 1.5rem;
  font-weight: 400;
  max-width: 600px;
}

.cta-section {
  margin-top: 1rem;
}

.cta-button {
  background: #f8c84a;
  color: #1a1a1a;
  border: none;
  padding: 1rem 2.5rem;
  font-weight: 600;
  font-size: 1.1rem;
  border-radius: 8px;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  gap: 0.75rem;
}

.cta-button:hover {
  background: #f9d06a;
  transform: translateY(-2px);
}

.cta-button i {
  font-size: 1.1rem;
  margin-top: 1px;
}

.contact-section {
  max-width: 900px;
  margin: 0 auto;
  padding: 0.75rem 2rem;
  background: #f8f9fa;
}

.contact-card {
  background: #ffffff;
  padding: 1.5rem;
  border-radius: 16px;
  border: 1px solid #eaeaea;
}

.contact-title {
  text-align: center;
  font-size: 1.25rem;
  color: #1a1a1a;
  margin-bottom: 1.5rem;
  font-weight: 600;
}

.contact-items {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3rem;
}

.contact-divider {
  width: 1px;
  height: 60px;
  background: #eaeaea;
}

.contact-item {
  flex: 1;
  text-align: center;
  max-width: 300px;
}

.contact-item i {
  font-size: 1.5rem;
  color: #f8c84a;
}

.branch-name {
  font-size: 1rem;
  color: #666;
  margin-bottom: 0.75rem;
  font-weight: 500;
}

.phone-number {
  font-size: 1.5rem;
  color: #1a1a1a;
  font-weight: 600;
  text-decoration: none;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}

.phone-number:hover {
  color: #25d366;
}

.phone-number i {
  color: #25d366;
  font-size: 1.25rem;
}

@media (max-width: 768px) {
  .navbar {
    padding: 1rem;
  }

  .main-logo {
    height: 80px;
  }

  .hero-section {
    padding: 0 1rem;
    min-height: 60vh;
  }

  .hero-content {
    margin-top: 0;
    padding: 2rem 0;
  }

  .hero-title {
    font-size: 2rem;
    padding: 0 0.5rem;
  }

  .hero-subtitle {
    font-size: 1rem;
    padding: 0 1rem;
  }

  .cta-button {
    padding: 0.875rem 2rem;
    font-size: 1rem;
    width: 100%;
    justify-content: center;
  }

  .contact-items {
    flex-direction: column;
    gap: 2rem;
  }

  .contact-divider {
    width: 80%;
    height: 1px;
    margin: 0 auto;
  }

  .contact-card {
    margin: 1rem;
    padding: 1.5rem;
  }

  .phone-number {
    font-size: 1.25rem;
  }
}

@media (max-width: 480px) {
  .main-logo {
    height: 70px;
  }

  .hero-title {
    font-size: 1.75rem;
  }

  .hero-subtitle {
    font-size: 0.95rem;
  }
}
</style>
