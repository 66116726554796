<template>
  <b-modal
    id="login_modal"
    hide-header
    hide-footer
    hide-backdrop
    centered
    content-class="login-modal"
    modal-class="custom-modal-dialog"
    body-class="p-0" 
  >
    <div class="modal-content-wrapper">
      <div class="modal-header-custom">
        <h5 class="modal-title">Sistemə Daxil Ol</h5>
        <button type="button" class="close-button" @click="$bvModal.hide('login_modal')">
          <i class="fas fa-times"></i>
        </button>
      </div>
      
      <div class="modal-body-custom">
        <div class="input-group">
          <label>İstifadəçi adı</label>
          <input
            type="text"
            v-model="username"
            placeholder="İstifadəçi adınızı daxil edin"
            class="custom-input"
            :class="{ error: errors.username }"
          />
          <div v-if="errors.username" class="error-message">İstifadəçi adı boş ola bilməz</div>
        </div>
        
        <div class="input-group">
          <label>Şifrə</label>
          <input
            type="password"
            v-model="password"
            placeholder="Şifrənizi daxil edin"
            class="custom-input"
            :class="{ error: errors.password }"
          />
          <div v-if="errors.password" class="error-message">Şifrə boş ola bilməz</div>
        </div>

        <div class="modal-footer-custom">
          <b-button @click="login" class="login-button">
            Daxil ol
            <i class="fas fa-sign-in-alt bg-transparent"></i>
          </b-button>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: "LoginModal",
  data() {
    return {
      username: null,
      password: null,
      errors: {
        username: false,
        password: false
      }
    };
  },
  watch: {
    username(newVal) {
      if (newVal) {
        this.errors.username = false;
      }
    },
    password(newVal) {
      if (newVal) {
        this.errors.password = false;
      }
    }
  },
  methods: {
    validateInputs() {
      this.errors.username = !this.username;
      this.errors.password = !this.password;
      return !this.errors.username && !this.errors.password;
    },
    login() {
      if (!this.validateInputs()) {
        return;
      }
      const data = {username: this.username, password: this.password};
      this.$store.dispatch("LOGIN", data);
    },
  },
};
</script>

<style>
/* Remove modal backdrop */
div.modal-backdrop.show {
  opacity: 0 !important;
  background: none !important;
}

body.modal-open {
  overflow: auto !important;
  padding-right: 0 !important;
}

.modal-dialog {
  background: none !important;
}

.modal {
  background-color: transparent !important;
}

/* Target the login-modal class specifically */
.login-modal {
  border: none !important;
  background: transparent !important;
  box-shadow: none !important;
}

.modal-content-wrapper {
  background: #fff;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.15);
}
</style>

<style scoped>
.login-modal {
  background: transparent !important;
  border: none !important;
  box-shadow: none !important;
}

.custom-modal-dialog {
  background: transparent;
}

.modal-content-wrapper {
  background: #fff;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.15);
}

.modal-header-custom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem;
  background: #f8f9fa;
  margin: 0;
}

.modal-title {
  font-size: 1.5rem;
  font-weight: 600;
  color: #1a1a1a;
  margin: 0;
}

.close-button {
  background: transparent;
  border: none;
  color: #666;
  font-size: 1.25rem;
  padding: 0.5rem;
  cursor: pointer;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  border-radius: 50%;
}

.close-button:hover {
  background: #eee;
  color: #1a1a1a;
}

.modal-body-custom {
  padding: 2rem;
}

.input-group {
  margin-bottom: 1rem;
}

.input-group label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: 500;
  color: #1a1a1a;
}

.custom-input {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 14px;
  transition: border-color 0.2s;
}

.custom-input.error {
  border-color: #dc3545;
}

.error-message {
  color: #dc3545;
  font-size: 12px;
  margin-top: 0.25rem;
}

.modal-footer-custom {
  margin: 0;
  padding: 1.5rem;
}

.login-button {
  width: 100%;
  background: #f8c84a;
  color: #1a1a1a;
  border: none;
  padding: 1rem;
  font-weight: 600;
  font-size: 1.1rem;
  border-radius: 8px;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.75rem;
}

.login-button:hover {
  background: #f9d06a;
  transform: translateY(-2px);
}

.login-button i {
  font-size: 1.1rem;
}

@media (max-width: 768px) {
  .modal-header-custom {
    padding: 1.25rem;
  }

  .modal-body-custom {
    padding: 1.5rem;
  }

  .modal-title {
    font-size: 1.25rem;
  }

  .custom-input {
    padding: 0.75rem;
  }

  .login-button {
    padding: 0.875rem;
    font-size: 1rem;
  }
}
</style>
