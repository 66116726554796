<template>
  <b-input-group class="rounded shadow-sm">
    <b-input-group-prepend v-if="name">
      <div
        class="pt-1 px-2 bg-light border font-weight-normal text-dark rounded-left"
      >
        {{ name }}
      </div>
    </b-input-group-prepend>
    <b-input
      v-model="my_value"
      :type="type ? type : 'text'"
      :placeholder="placeholder ? placeholder : ''"
      :required="!!required"
      trim
      class="border"
      autocomplete="off"
    />
  </b-input-group>
</template>
<script>
export default {
  name: "InputText",
  props: ["name", "value", "required", "type", "placeholder"],
  computed: {
    my_value: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
};
</script>
<style scoped>
.b-input-group {
  width: 100%;
}

.b-input {
  height: 40px;
  font-size: 1rem;
  padding: 0.5rem 1rem;
}

@media (max-width: 768px) {
  .b-input-group {
    margin: 0.5rem 0;
  }

  .b-input {
    height: 44px;
    font-size: 16px !important; /* Prevents iOS zoom on focus */
    padding: 0.75rem;
  }

  .b-input-group-prepend {
    min-width: 80px;
  }

  .b-input-group-prepend div {
    height: 44px;
    display: flex;
    align-items: center;
    padding: 0 1rem !important;
    font-size: 14px;
  }
}
</style>
